import React from "react";
import { View, Alert, Text, Heading } from '@aws-amplify/ui-react';
import HintBox from './HintBox';


class InstructionContainer extends React.Component {
    
    constructor(props) {
        super(props);
    }
    
    createMarkup() {
        return {
            __html: this.props.text
        }
    }
    
    render() {
        return <View id="info-container">
                    <Heading level={2}>{this.props.heading}</Heading>
                    {this.props.text?<Text className="instruction-text" dangerouslySetInnerHTML={this.createMarkup()}></Text>:null}
                    {this.props.hints?<HintBox hints={this.props.hints} />:null}
                    {this.props.info?<Alert variation="info">{this.props.info}</Alert>:null}
                </View>
    }
}

export default InstructionContainer;