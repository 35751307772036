import React from "react";
import { View, Button } from '@aws-amplify/ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera, faCircleRight } from '@fortawesome/free-solid-svg-icons'
import { Stack, Slider, Box } from '@mui/material/'


class ButtonBar extends React.Component {
    
    constructor(props) {
        super(props);
    } 
    
    render() {     
            let camera;
        
            return <View className="button-bar">    
                        {this.props.camera?
                            <Button className="icon-button" onClick={this.props.camera} ref={this.props.camera? this.props.buttonRef: null}><FontAwesomeIcon icon={faCamera} size="4x"/></Button>
                            :null
                        }
                        {this.props.nextButton?
                            <Button className="icon-button" onClick={this.props.nextButton} ref={this.props.nextButton? this.props.buttonRef : null}>
                                <FontAwesomeIcon icon={faCircleRight} size="4x"/>
                            </Button>
                            :null
                        }
                        {this.props.debug?
                            <div>
                                <Button onClick={()=>{this.props.debugSetStep("loading")}}>simulate loading</Button>
                                <Button onClick={()=>{this.props.debugSetStep("start")}}>reset</Button> 
                                <span>&nbsp;|&nbsp;</span>
                                <input type="file" id="fileupload" accept="image/jpeg" />
                                <Button onClick={this.props.debugFileUpload}>manual fileupload</Button>
                                <Box sx={{ width: 200 }}>
                                    <p>Confidence Score: {this.props.debugConfidenceScore}</p>
                                    <Slider aria-label="Volume" value={this.props.debugConfidenceScore} onChange={this.props.debugSliderState} />
                                </Box>
                            </div>
                            :null
                        }

                   </View>;
                  
        
    }
}

export default ButtonBar;