import React from "react";
import { View, Text, Heading, Button } from '@aws-amplify/ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear } from '@fortawesome/free-solid-svg-icons'
import QRCode from "react-qr-code";


class InstructionContainer extends React.Component {
    
    constructor(props) {
        super(props);
    }
    

    
    render() {
        return <View id="info-container">
                    
                    {this.props.loading?
                    <div style={{textAlign:"center", width: "100%", marginBottom:"10rem", marginTop: "10rem"}}>
                        <Heading level={2}>Deploying your revised architecture</Heading>
                        <FontAwesomeIcon icon={faGear} className="fa-spin" size="4x"/>
                        <Heading level={2}> Please wait...</Heading>
                    </div>
                    :
                    <div>
                    <Heading level={2}>Scan this QR Code to download a PDF with your architecture!</Heading>
                    <div style={{ padding: '3rem' }}>
                    <QRCode value={this.props.qrUrl} />
                    </div>
                    </div>
                    }
                    
                </View>
    }
}

export default InstructionContainer;