import React from "react";
import { View } from '@aws-amplify/ui-react';
import Webcam from "react-webcam";

function WebcamView(props) {
    return <View id="card-webcam">
            <div id="video-overlay">
             &nbsp;
            </div>
            <Webcam audio={false} ref={props.fref} screenshotFormat="image/jpeg" minScreenshotWidth="1280" screenshotQuality="1" videoConstraints={{
              width: 1280,
              height: 720
            }}	/>
          </View>
}

export default WebcamView;