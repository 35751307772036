import React from "react";
import { View, Flex, Heading } from '@aws-amplify/ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear } from '@fortawesome/free-solid-svg-icons'



class Loading extends React.Component {
    
    constructor(props) {
        super(props);
    }

    render() {
        return <Flex style={{width: "100%"}}>
                <div style={{textAlign:"center", width: "100%", marginBottom:"10rem", marginTop: "10rem"}}>
                    <FontAwesomeIcon icon={faGear} className="fa-spin" size="4x"/>
                    <Heading level={2}> Please wait...</Heading>
                </div>
               </Flex>
    }
    
}

export default Loading;