import React from "react";
import { Button } from '@aws-amplify/ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb } from '@fortawesome/free-solid-svg-icons'
//import { View, Alert, Text, Heading } from '@aws-amplify/ui-react';


function HintBox(props) {
    
    const [hintCounter, setHintCounter] = React.useState(0);
    
    let hintText = <p>Need a hint?</p>;
    
    if(hintCounter>0) {
        hintText = <p>({hintCounter}/{props.hints.length}) {props.hints[hintCounter-1]}</p>;
    }
    
    const nextHint = () => {
        if(hintCounter==props.hints.length) {
            setHintCounter(1);
        } else {
            setHintCounter(hintCounter+1);
        }
    }
    
    return (<div className="hint-box">
                    <div className="hint-button-container">
                        <Button className="icon-button" onClick={nextHint}>
                            <FontAwesomeIcon icon={faLightbulb} size="4x"/>
                        </Button>
                    </div>
                    <div className="hint-text">
                        {hintText}
                    </div>
                </div>);
}

export default HintBox;