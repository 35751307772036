import React from "react";
import { View } from '@aws-amplify/ui-react';



class ResultView extends React.Component {
    
    constructor(props) {
        super(props);
    }


    render() {
        return <View id="card-webcam">
                <div id="video-overlay">
                 &nbsp;
                </div>
                <img src={this.props.annotatedImageUrl} />
              </View>
    }
    
}

export default ResultView;