import React from "react";
import Amplify, { Storage } from "aws-amplify";
import {
  AmplifyProvider,
  View,
  Flex,
  Button,
  Authenticator,
  Heading,
  Alert,
  Text,
} from "@aws-amplify/ui-react";
import Webcam from "react-webcam";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faCubes,
  faGear,
  faCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import WebcamView from "./components/WebcamView";
import ResultView from "./components/ResultView";
import InstructionContainer from "./components/InstructionContainer";
import DeployContainer from "./components/DeployContainer";
import ButtonBar from "./components/ButtonBar";
import Loading from "./components/Loading";
import { v4 as uuid } from "uuid";
import { useEffect } from "react";
import { useKeyPressEvent } from "react-use";

const tenant = localStorage.getItem("brickformation-tenant") || "default";
const api =
  localStorage.getItem("brickformation-apiurl") ||
  "https://5d6i2xcpq6.execute-api.eu-central-1.amazonaws.com/prod";
const crossAccountRoleArn =
  localStorage.getItem("brickformation-crossaccountrolearn") ||
  "arn:aws:iam::393150529218:role/BrickFormationCrossAccountModelRodel";
const rekognitionProjectArn =
  localStorage.getItem("brickformation-rekognitionprojectarn") ||
  "arn:aws:rekognition:eu-central-1:393150529218:project/BrickformationMasterModel/1655307529731";
const scenarioBucket =
  localStorage.getItem("brickformation-scenariobucket") ||
  "brickformation-scenario-assets";
const awsaccountid =
  localStorage.getItem("brickformation-awsaccountid") || "550095356744";
const awsregion =
  localStorage.getItem("brickformation-awsregion") || "eu-central-1";

function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  /*global atob*/
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  /*global Blob*/
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

function App() {
  const webcamRef = React.useRef(null);

  const queryString = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  // const [searchParams, setSearchParams] = useSearchParams();

  const [annotatedImageUrl, setAnnotatedImageUrl] = React.useState("");
  const [step, setStep] = React.useState("start");
  const [headline, setHeadline] = React.useState("");
  const [text, setText] = React.useState("");
  const [info, setInfo] = React.useState("");
  const [next, setNext] = React.useState("");
  const [solved, setSolved] = React.useState(false);
  const [deployUrl, setDeployUrl] = React.useState("");
  const [scenarioName, setScenarioName] = React.useState("");
  const [hints, setHints] = React.useState("");
  const [customErrorHeadline, setCustomErrorHeadline] = React.useState("");
  const [customErrorText, setCustomErrorText] = React.useState("");
  const [deployLoading, setDeployLoading] = React.useState(false);
  const [confidenceScore, setConfidenceScore] = React.useState(0.4);
  const [qrCodeUrl, setQrCodeUrl] = React.useState("");
  const [cloudFormationUrl, setCloudFormationUrl] = React.useState("");
  const [pdfKey, setPdfKey] = React.useState("");
  const buttonRef = React.useRef(null);

  async function setSliderValue(event, newValue) {
    setConfidenceScore(newValue / 100);
  }

  async function processFileUpload(retake) {
    const reader = new FileReader();
    reader.addEventListener("load", async function () {
      process(reader.result, retake);
    });
    const file = document.querySelector("input[type=file]").files[0];
    reader.readAsDataURL(file);
  }

  async function processCamera(retake) {
    process(webcamRef.current.getScreenshot(), retake);
  }

  async function process(data, retake) {
    // Activate Loading Spinner
    setStep("loading");
    // Generate Random Filename (can be improved!!!)
    let key = tenant + "/" + uuid() + ".jpg";
    // Upload Image
    await Storage.put(key, dataURItoBlob(data), {});
    // Call First Lambda
    /*global fetch*/
    // TO DO: fetch with query parameter from fetch function
    console.log("vault", Storage.vault);
    fetch(api + "/execute", {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        imageKey: "public/" + key,
        imageBucket: Storage.vault._config.AWSS3.bucket,
        confidenceScore: confidenceScore,
        showLabel: 1,
        crossAccountRoleArn: crossAccountRoleArn,
        rekognitionProjectArn: rekognitionProjectArn,
        scenarioBucket: scenarioBucket,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // Set Result Image
        setAnnotatedImageUrl(data.result.annotatedImage);
        // Handle result
        if (!retake) {
          displayResultInitial(data.result, key);
        } else {
          displayResultRetake(data.result, key);
        }
      });
  }

  async function displayResultInitial(result, key) {
    console.log(result);
    if (result.status == "ok") {
      setHeadline(result.headline);
      setText(result.text);
      setHints(result.hints);
      setCustomErrorHeadline(result.customErrors.headline);
      setCustomErrorText(result.customErrors.text);
      setNext("scenario_recognized_retake");
      setStep("scenario_recognized");
    } else {
      if (queryString.debug == "true") {
        setText(
          "<code>isengardcli run --account 'mssrli+brickformation+management' \"aws s3 cp s3://reactamplifiedd8e5faa53a9e41599536203ebb9daee2151405-dev/public/" +
            key +
            ' ."</code>'
        );
      } else {
        setText("");
      }
      setStep("scenario_not_recognized");
    }
  }

  async function displayResultRetake(result, key) {
    console.log(result);
    if (result.state == "solved") {
      setHeadline("Congratulations!");
      setText("You have sucessfully solved this scenario.");
      setInfo("Click next to deploy the architecture");
      //setDeployUrl(result.deployUrl);
      setScenarioName(result.name);
      //setCloudFormationUrl(result.cloudFormationUrl);
      setPdfKey(result.pdfKey);
      setSolved(true);
    } else {
      setHeadline(customErrorHeadline);
      if (queryString.debug == "true") {
        setText(
          customErrorText +
            "<code>isengardcli run --account 'mssrli+brickformation+management' \"aws s3 cp s3://reactamplifiedd8e5faa53a9e41599536203ebb9daee2151405-dev/public/" +
            key +
            ' ."</code>'
        );
      } else {
        setText(customErrorText);
      }
      setInfo(
        "Add or change services to solve the scenario. Once you are done, click on the button to continue."
      );
      setNext("scenario_recognized_retake");
    }
    setStep("scenario_recognized");
  }

  function solvedOrNext(next) {
    if (solved) {
      deployAndNext();
    } else {
      setStep(next);
    }
  }

  function deployAndNext() {
    deploy(scenarioName);
    setStep("scenario_deploy");
    setSolved(false);
  }

  async function deploy(scenarioName) {
    if (queryString.deploy === "false") {
      displayQr();
    } else {
      setDeployLoading(true);
      fetch(api + "/deploy", {
        mode: "cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          scenarioBucket: scenarioBucket,
          scenarioName: scenarioName,
          solved: "true",
          awsaccountid: awsaccountid,
          awsregion: awsregion,
        }),
      });
      window.cfurl =
        "https://" + awsregion + ".console.aws.amazon.com/cloudformation/";
      setTimeout(() => {
        // ToDo: get rid of hardcoded URL, dynamically filter CF based on stack name. Later federate into second
        window.open(window.cfurl, "_blank").focus();
        displayQr();
      }, 10000);
    }
  }

  async function displayQr() {
    let data = {
      "imageKey": annotatedImageUrl.match(/(\.com\/)(.*)(\?)/)[2],
      "imageBucket": Storage.vault._config.AWSS3.bucket,
      "pdfTemplateBucket": scenarioBucket,
      "scenarioName": scenarioName
    };
    fetch(
      api+"/generatePdf",
      { method: "POST", body: JSON.stringify(data), mode: "cors" }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setQrCodeUrl(result.UrlToPdf);
        setDeployLoading(false);
      });
  }

  //Key Actions for Buzzer :)
  useKeyPressEvent("b", () => {
    if (step == "start") {
      processCamera(false);
    }
    if (step == "scenario_recognized") {
      solvedOrNext(next);
    }
    if (step == "scenario_not_recognized") {
      setStep("start");
    }
    if (step == "scenario_recognized_retake") {
      processCamera(true);
    }
    if (step == "scenario_deploy") {
      // do nothing at the moment to not lose the QR-Code.
      // setStep('start');
    }
  });

  let content_left, content_right, buttons;

  if (step == "start") {
    content_left = <WebcamView fref={webcamRef} />;
    content_right = (
      <InstructionContainer
        heading="Let's start!"
        info="Place a prepared base plate with bricks under the camera and align it to fit the frame. Then press the camera button to begin."
      />
    );
    buttons = (
      <ButtonBar
        camera={() => {
          processCamera(false);
        }}
        debug={queryString.debug}
        debugSetStep={setStep}
        debugFileUpload={() => {
          processFileUpload(false);
        }}
        debugSliderState={setSliderValue}
        debugConfidenceScore={confidenceScore}
      />
    );
  }

  if (step == "scenario_recognized") {
    content_left = <ResultView annotatedImageUrl={annotatedImageUrl} />;
    content_right = (
      <InstructionContainer heading={headline} text={text} info={info} />
    );
    buttons = (
      <ButtonBar
        nextButton={() => {
          solvedOrNext(next);
        }}
      />
    );
  }

  if (step == "scenario_not_recognized") {
    content_left = <ResultView annotatedImageUrl={annotatedImageUrl} />;
    content_right = (
      <InstructionContainer
        heading="No scenario recognized."
        text={text}
        info="Click next to try again"
      />
    );
    buttons = (
      <ButtonBar
        nextButton={() => {
          setStep("start");
        }}
      />
    );
  }

  if (step == "scenario_recognized_retake") {
    content_left = <WebcamView fref={webcamRef} />;
    content_right = (
      <InstructionContainer
        heading={headline}
        text={text}
        hints={hints}
        info="Place the modified scenario under the camera and align it to fit the frame. Then press the camera button to continue."
      />
    );
    buttons = (
      <ButtonBar
        camera={() => {
          processCamera(true);
        }}
        debug={queryString.debug}
        debugSetStep={setStep}
        debugFileUpload={() => {
          processFileUpload(true);
        }}
        debugSliderState={setSliderValue}
        debugConfidenceScore={confidenceScore}
      />
    );
  }

  if (step == "scenario_deploy") {
    content_left = <ResultView annotatedImageUrl={annotatedImageUrl} />;
    content_right = (
      <DeployContainer loading={deployLoading} qrUrl={qrCodeUrl} />
    );
    buttons = (
      <ButtonBar
        nextButton={() => {
          setStep("start");
        }}
      />
    );
  }

  if (step == "loading") {
    content_left = <Loading />;
    content_right = "";
    buttons = "";
  }

  return (
    <Authenticator>
      {({ signOut, user }) => (
        <AmplifyProvider>
          <div id="outer-div">
            <Flex className="header">
              <Heading level={1} style={{ color: "#FF9900" }}>
                <FontAwesomeIcon icon={faCubes} />{" "}
                <span style={{ color: "#FFF" }}>Brick</span> Formation
              </Heading>
            </Flex>
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              alignContent="flex-start"
              wrap="nowrap"
              gap="1rem"
              className="main-container"
            >
              {content_left}
              {content_right}
            </Flex>
            <Flex>{buttons}</Flex>
          </div>
        </AmplifyProvider>
      )}
    </Authenticator>
  );
}

export default App;
